<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="550" scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Hay pagos pendientes
        </v-card-title>

        <v-card-text>
          <v-alert prominent text type="error" class="mt-5">
            <p class="text-body-1 mb-0">
              Para poder promover a los estudiantes al siguiente curso, todos
              deben estar solventes con el pago de todos los cursos previamente
              cursados.
            </p></v-alert
          >

          <v-alert prominent text type="warning" class="mt-5">
            <p class="text-body-1 mb-0">
              Si se continua con la promoción, los estudiantes en mora
              <span class="font-weight-bold"
                >NO podrán ser promovidos hasta que se solventen los pagos
                pendientes.</span
              >
            </p></v-alert
          >

          <p class="text-body-1 font-weight-medium">
            Los siguientes estudiantes no serán promovidos ya que cuentan con
            pagos pendientes:
          </p>

          <div v-for="student in studentsInDebt">
            <v-divider></v-divider>
            <p class="mt-2 font-weight-bold">
              <v-icon color="red">mdi-account-remove </v-icon>
              {{ student.first_name }}
              {{ student.last_name }}
            </p>
            <v-card
              v-for="pendingCourse in student.pending_payments"
              elevation="3"
              class="px-5 pb-5 pt-2 mb-5"
            >
              <p class="text-overline mb-0 red--text">
                Curso no pagado
              </p>
              <p class="font-weight-medium text-body-1 mb-0">
                {{ pendingCourse.course }}
              </p>
              <p class="font-weight-normal text-body-2 mb-0">
                {{ pendingCourse.cycle }}
              </p>
            </v-card>
            <v-divider></v-divider>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="toggleModal()">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="promote()">
            Si, continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PaymentValidatorModal",

  data() {
    return {
      dialog: false,
    };
  },
  props: {
    studentsInDebt: {
      type: Array,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    promote() {
      this.$emit("promote-anyway");
      this.toggleModal();
    },
  },
};
</script>
