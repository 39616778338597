<template>
  <div>
    <v-banner>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
            {{ course.program }} {{ course.course }}
          </p>
          <p class="mb-0">
            {{ course.cycle }}
          </p>
          <p class="mb-0 font-weight-medium">
            Total de estudiantes inscritos: {{ total_enrolled }}
          </p>
          <p class="mb-0 font-weight-medium">
            Total pagado por los estudiantes inscritos: ${{ total_paid_enrolled }}
          </p>
        </v-col>
        <v-col v-if="course.status != 3" cols="12" sm="12" md="8">
          <v-btn
            class="float-right"
            v-if="searchInArray(actions, 'Calificar')"
            color="primary"
            @click="modalUpload()"
            :loading="isLoading"
          >
            <v-icon left>
              mdi-cloud-upload
            </v-icon>
            Subir Calificaciones
          </v-btn>
          <v-btn
            class="float-right mr-2"
            v-if="searchInArray(actions, 'Finalizar')"
            color="red white--text"
            @click="modalFinish()"
            :loading="isLoading"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
            Finalizar Curso
          </v-btn>
        </v-col>
        <v-col v-else cols="12" sm="12" md="8">
          <h3 class="text-right mr-2">
            <v-icon color="green" large left>
              mdi-check-circle
            </v-icon>
            Curso Finalizado.
          </h3>
        </v-col>
      </v-row>
    </v-banner>

    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Calificar')"
              :headers="data.headers"
              :items="data.data"
              :items-per-page="-1"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de estudiantes
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <vue-json-to-csv
                            :json-data="csvData"
                            :labels="labelsData"
                            :csv-title="
                              'Lista De Alumnos - ' +
                                course.program +
                                ' ' +
                                course.course
                            "
                          >
                            <v-icon>mdi-file-excel</v-icon>
                            Descargar CSV
                          </vue-json-to-csv>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Nota -->
              <template v-slot:[`item.final_score`]="{ item }">
                <v-text-field
                  v-model="item.final_score"
                  placeholder="Nota"
                  dense
                  outlined
                  hide-details
                  @input="save(item)"
                ></v-text-field>
                <!-- <span v-else>
                  {{ item.final_score }}
                </span> -->
              </template>
              <!-- payment state -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'Preinscrito'"
                  class="ma-2"
                  color="red darken-1 white--text"
                  pill
                >
                  <v-icon left>
                    mdi-alert-circle-outline
                  </v-icon>
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-if="item.status === 'Retirado'"
                  class="ma-2"
                  color="purple darken-2 white--text"
                  pill
                >
                  <v-icon left>
                    mdi-alert-circle-outline
                  </v-icon>
                  {{ item.status }}
                </v-chip>
                <v-chip  v-if="item.status === 'Inscrito'" class="ma-2" color="success" pill>
                  <v-icon left>
                    mdi-check-circle
                  </v-icon>
                  {{ item.status }}
                </v-chip>
              </template>

              <template v-slot:[`item.total_paid`]="{item}">
                <v-chip  v-if="item.status === 'Inscrito'" class="ma-2" color="success" pill>
                  <v-icon left>
                    mdi-currency-usd
                  </v-icon>
                  {{ item.total_paid }}
                </v-chip>
              </template>

              <!-- Reubicación -->
              <template v-slot:[`item.reubicar`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        selectStudent(item);
                        modalReubicar();
                      "
                      :disabled="course.status == 3 || item.status == 5"
                    >
                      <v-icon color="azul">mdi-cogs</v-icon>
                    </v-btn>
                  </template>
                  <span>Reubicar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        selectStudent(item);
                        modalStatus();
                      "
                      :disabled="course.status == 3 || item.status == 5"
                    >
                      <v-icon color="red" v-if="item.status !== 'Retirado'">mdi-account-edit</v-icon>
                      <v-icon color="red darken-3" v-else>mdi-badge-account-alert</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="item.status !== 'Retirado'">Dar de baja</span>
                  <span v-else>Detalles del retiro</span>
                </v-tooltip>
                <v-tooltip bottom v-if="searchInArray(actions, 'Ver')">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-5"
                      @click="
                        selectStudent(item);
                        modalRecord();
                      "
                      color="blue-grey"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-file
                    </v-icon>
                  </template>
                  <span>Expediente</span>
                </v-tooltip>
              </template>

              <!-- Reser data -->
              <template>
                <v-btn color="primary" @click="getStudents()">
                  Reiniciar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-banner>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
            {{ course.program }} {{ course.course }}
          </p>
          <p class="mb-0">
            {{ course.cycle }}
          </p>
        </v-col>
        <v-col v-if="course.status != 3" cols="12" sm="12" md="8">
          <v-btn
            class="float-right"
            v-if="searchInArray(actions, 'Calificar')"
            color="primary"
            @click="modalUpload()"
            :loading="isLoading"
          >
            <v-icon left>
              mdi-cloud-upload
            </v-icon>
            Subir Calificaciones
          </v-btn>
          <v-btn
            class="float-right mr-2"
            v-if="searchInArray(actions, 'Finalizar')"
            color="red white--text"
            @click="modalFinish()"
            :loading="isLoading"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
            Finalizar Curso
          </v-btn>
        </v-col>
        <v-col v-else cols="12" sm="12" md="8">
          <h3 class="text-right mr-2">
            <v-icon color="green" large left>
              mdi-check-circle
            </v-icon>
            Curso Finalizado.
          </h3>
        </v-col>
      </v-row>
    </v-banner>

    <v-dialog v-model="dialogUpload" width="400" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Subir Calificaciones</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-file-input
                  accept="text/csv"
                  label="Calificaciones (CSV)"
                  placeholder="Seleccione el archivo."
                  prepend-icon=""
                  prepend-inner-icon="mdi-file"
                  outlined
                  hide-details
                  v-model="uploadFrm.file"
                />
              </v-col>
            </v-row>
          </v-container> </v-card-text
        ><v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="closeUpload()">
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="uploadFile()"
            :loading="isLoading"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFinish" width="700" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Finalizar Curso</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Curso: </span>
                  <span> {{ course.course }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Programa: </span>
                  <span> {{ course.program }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Idioma: </span>
                  <span> {{ course.language }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Ciclo: </span>
                  <span> {{ course.cycle }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Horas: </span>
                  <span> {{ course.hours }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Nota minima: </span>
                  <span> {{ course.minimum_score }} </span>
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <h4 class="black--text mb-0">Promover alumnos Aprobados</h4>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      label="Promover alumnos al curso"
                      :items="future_courses"
                      item-text="course"
                      item-value="id_course"
                      required
                      outlined
                      hide-details
                      v-model="finishFrm.id_course_next"
                      @change="selectCourseNext"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="course_next.program">
                  <v-col cols="12" class="pb-0">
                    <p class="black--text mb-0">
                      <span class="font-weight-bold">Programa: </span>
                      <span> {{ course_next.program }} </span>
                    </p>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <p class="black--text mb-0">
                      <span class="font-weight-bold">Idioma: </span>
                      <span> {{ course_next.language }} </span>
                    </p>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <p class="black--text mb-0">
                      <span class="font-weight-bold">Ciclo: </span>
                      <br />
                      <span> {{ course_next.cycle }} </span>
                      <br />
                      <span>
                        De
                        {{ course_next.start_cycle }}
                        Hasta
                        {{ course_next.end_cycle }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-row>
                  <v-col cols="12" md="12" class="pb-0">
                    <h4 class="black--text mb-0">Alumnos Reprobados</h4>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                      label="Repetir curso"
                      :items="future_courses"
                      item-text="course"
                      item-value="id_course"
                      required
                      outlined
                      hide-details
                      v-model="finishFrm.id_course_back"
                      @change="selectCourseBack"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row v-if="course_back.program">
                  <v-col cols="12" class="pb-0">
                    <p class="black--text mb-0">
                      <span class="font-weight-bold">Programa: </span>
                      <span> {{ course_back.program }} </span>
                    </p>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <p class="black--text mb-0">
                      <span class="font-weight-bold">Idioma: </span>
                      <span> {{ course_back.language }} </span>
                    </p>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <p class="black--text mb-0">
                      <span class="font-weight-bold">Ciclo: </span>
                      <br />
                      <span> {{ course_back.cycle }} </span>
                      <br />
                      <span>
                        De
                        {{ course_back.start_cycle }}
                        Hasta
                        {{ course_back.end_cycle }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container> </v-card-text
        ><v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="closeFinish()">
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="finishCourse()"
            :loading="isLoading"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReubicar" width="700" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Reubicar Estudiante</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Curso: </span>
                  <span> {{ course.course }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Programa: </span>
                  <span> {{ course.program }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Idioma: </span>
                  <span> {{ course.language }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Ciclo: </span>
                  <span> {{ course.cycle }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Horas: </span>
                  <span> {{ course.hours }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Nota minima: </span>
                  <span> {{ course.minimum_score }} </span>
                </p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" md="12" class="pb-0">
                <h4 class="black--text mb-0">Reubicar alumno</h4>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  label="Reubicar alumno al curso"
                  :items="actual_courses"
                  item-text="course_custom"
                  item-value="id_course"
                  required
                  outlined
                  hide-details
                  v-model="reubicarFrm.id_course"
                  @change="selectCourseReubicar"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="course_actual.program">
              <v-col cols="12" class="pb-0">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Programa: </span>
                  <span> {{ course_actual.program }} </span>
                </p>
              </v-col>
              <v-col cols="12" class="pb-0">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Idioma: </span>
                  <span> {{ course_actual.language }} </span>
                </p>
              </v-col>
              <v-col cols="12" class="pb-0">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Ciclo: </span>
                  <br />
                  <span> {{ course_actual.cycle }} </span>
                  <br />
                  <span>
                    De
                    {{ course_actual.start_cycle }}
                    Hasta
                    {{ course_actual.end_cycle }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="closeReubicar()">
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="reubicar()"
            :loading="isLoading"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStatus" width="700" persistent>
      <v-card>
        <v-card-title>
          <span class="headline" v-if="selectedStudent.status !== 'Retirado'">Dar de baja al Estudiante del curso</span>
          <span class="headline" v-else>Detalles del retiro del estudiante</span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Curso: </span>
                  <span> {{ course.course }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Programa: </span>
                  <span> {{ course.program }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Idioma: </span>
                  <span> {{ course.language }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Ciclo: </span>
                  <span> {{ course.cycle }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Horas: </span>
                  <span> {{ course.hours }} </span>
                </p>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <p class="black--text mb-0">
                  <span class="font-weight-bold">Nota minima: </span>
                  <span> {{ course.minimum_score }} </span>
                </p>
              </v-col>
              <v-col cols="12">
                <v-textarea 
                label="Motivo" 
                outlined 
                v-model="removeStudentMotive"
                height="100"
                hide-details
                :readonly="isStudentRetired"
                class="mb-3"/>
                <span class="red--text text--darken-3" v-if="isStudentRetired">
                  Retirado por: {{ selectedStudent.user_who_unsuscribed }}
                </span>
                <div v-if="isStudentRetired && HasMissingPayments" class="mt-3">
                  <span class="orange--text text--darken-4 text-weight-bold">
                    Nota:
                  </span>
                  <span class="orange--text text--darken-4">
                    El estudiante aún no ha realizado el pago correspondiente, en el caso de reincoporarlo, pasará a "Preinscrito".
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" text @click="closeStatus()">
            {{ isStudentRetired ? 'Cerrar' : 'Cancelar' }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="changeStatus(5)"
            :loading="statusLoad"
            v-if="!isStudentRetired"
          >
            Dar de baja al alumno
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="changeStatus(1)"
            :loading="statusLoad"
            v-if="isStudentRetired && !HasMissingPayments">
            Reincorporar al curso
          </v-btn>
          <v-btn
            color="orange darken-4"
            text
            @click="changeStatus(4)"
            :loading="statusLoad"
            v-if="isStudentRetired && HasMissingPayments">
            Reincoporar con pagos pendientes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- payment verification modal -->
    <PaymentValidatorModal
      ref="mdlValidatePayment"
      :studentsInDebt="pendingPaymentStudentsList"
      @promote-anyway="getNextCourseDetailsAndPromote"
    ></PaymentValidatorModal>
    <RecordStudent ref="mdlRecord" :student="selectedStudent" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueJsonToCsv from "vue-json-to-csv";
import RecordStudent from '../Student/RecordStudent.vue';
import PaymentValidatorModal from "@/components/PaymentValidatorModal.vue";

export default {
  name: "CourseProgram",
  components: {
    VueJsonToCsv,
    PaymentValidatorModal,
    RecordStudent,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        { text: "Código", value: "code" },
        { text: "Nombres", value: "first_name" },
        { text: "Apellidos", value: "last_name" },
        { text: "Correo", value: "email" },
        { text: "Nota Final", value: "final_score", sortable: false },
        { text: "Contraseña Moodle", value: "password_fake", sortable: false },
        { text: "Estado del Alumno", value: "status" },
        { text: "Total Pagado", value: "total_paid" },
      ],
      data: [],
    },
    total_enrolled: 0,
    total_paid_enrolled: 0,
    pendingPaymentStudentsList: [],
    future_courses: [],
    actual_courses: [],
    uploadFrm: {},
    finishFrm: {},
    reubicarFrm: {},
    statusFrm: {},
    course_back: {},
    course_actual: {},
    course_next: {},
    id_course: 0,
    course: {},
    statements: ["Inscrito", "Preinscrito", "Retirado"],
    dialogUpload: false,
    dialogFinish: false,
    dialogReubicar: false,
    dialogStatus: false,
    isLoading: false,
    statusLoad: false,
    search: "",
    selectedStudent: {},
    removeStudentMotive: ""
  }),
  mounted() {
    this.getCurrentActions("Cursos");
    this.id_course = this.$route.params.id;
    this.getStudents();
    this.getCourse();
  },

  methods: {
    showMdlPaymentValidator() {
      this.$refs.mdlValidatePayment.toggleModal();
    },
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionamiento
    getStudents: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "course_student/course/" + this.id_course)
        .then(({ data }) => {
          data.forEach((item) => {
            if (item.status==1 || item.status==2 || item.status==3) {
               item.status = "Inscrito";
               //Sumando el total de estudiantes inscritos
               this.total_enrolled++;
               //Sumando el total de pagos por estudiantes inscritos
               this.total_paid_enrolled+=Number(item.total_paid);
            }
             if (item.status==4) {
               item.status = "Preinscrito";
            }
             if (item.status==5) {
               item.status = "Retirado";
            }
          });
          this.data.data = data; 
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
    
    getCourse: async function() {
      await this.$http
        .get(this.api + "course/one/" + this.id_course)
        .then(({ data }) => {
          this.course = data;
        });
    },
    getCourseFuture: async function() {
      await this.$http
        .get(this.api + "course/future/" + this.id_course)
        .then(({ data }) => {
          this.future_courses = data;
        });
    },
    save: function(item) {
      if (item.final_score) {
        if (!isNaN(item.final_score)) {
          this.$http
            .post(this.api + "course_student", this.toFormData(item))
            .then(({ data }) => {
              this.openSnackbar({
                type: data.response ? "success" : "warning",
                message: data.message,
              });
            })
            .catch((err) => {
              this.openSnackbar({
                type: "danger",
                message: "Error al realizar la petición",
              });
            })
            .then(() => {
              item.loading = false;
            });
        } else {
          this.openSnackbar({
            type: "info",
            message: "El valor debe ser un número",
          });
        }
      } else {
        this.openSnackbar({
          type: "info",
          message: "Debe ingresar un valor",
        });
      }
    },
    uploadFile: async function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "course_student/csv", this.toFormData(this.uploadFrm))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeUpload();
            this.cleanUpload();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    closeUpload() {
      this.dialogUpload = false;
    },

    modalUpload() {
      this.uploadFrm.id_course = this.course.id_course;
      this.dialogUpload = true;
    },

    cleanUpload() {
      this.uploadFrm = {};
      this.getStudents();
    },

    finishCourse: async function() {
      this.isLoading = true;
      await this.$http
        .post(
          this.api + "course_student/finish",
          this.toFormData(this.finishFrm)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeFinish();
            this.cleanFinish();
            this.getCourse();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    selectCourseNext() {
      this.course_next = this.future_courses.find(
        (course) => course.id_course == this.finishFrm.id_course_next
      );
    },

    selectCourseBack() {
      this.course_back = this.future_courses.find(
        (course) => course.id_course == this.finishFrm.id_course_back
      );
    },

    modalRecord() {
      this.$refs.mdlRecord.changeModal();
    },

    closeFinish() {
      this.dialogFinish = false;
    },

    modalFinish() {
      let finalizar = true;
      //Lista de estudiantes -> this.data.data.length
      if (this.data.data.length > 0) {
        //por cada estudiante en la lista
        this.data.data.forEach((item) => {
          if (item.final_score == null) {
            finalizar = false;
          }
        });
      } else {
        finalizar = false;
      }
      //si ya están todas las notas
      if (finalizar) {
        this.pendingPaymentStudentsList = [];
        //recopilar todos los estudiantes que tienen pagos pendientes
        this.data.data.forEach((student) => {
          if (student.pending_payments.length > 0) {
            this.pendingPaymentStudentsList.push(student);
          }
        });
        //si hay almenos un esudiante con el pago pendiente
        if (this.pendingPaymentStudentsList.length > 0) {
          this.showMdlPaymentValidator();
        } else {
          //si no hay estudiantes con pago pendientes
          this.getNextCourseDetailsAndPromote();
        }
      } else {
        this.openSnackbar({
          type: "info",
          message: "Debe ingresar todas las calificaciones.",
        });
      }
    },

    getNextCourseDetailsAndPromote() {
      this.getCourseFuture();
      this.finishFrm.id_course = this.course.id_course;
      this.dialogFinish = true;
    },

    cleanFinish() {
      this.finishFrm = {};
      this.getStudents();
    },

    //reubicar
    getCourseReubicar: async function() {
      await this.$http.get(this.api + "course/all").then(({ data }) => {
        data.forEach((item) => {
          item.course_custom =
            item.program + " " + item.course + "[" + item.cycle + "]";
        });
        this.actual_courses = data;
      });
    },

    selectCourseReubicar() {
      this.course_actual = this.actual_courses.find(
        (course) => course.id_course == this.reubicarFrm.id_course
      );
    },

    selectStudent(item) { 
      this.selectedStudent = item;
      this.reubicarFrm.id_student = item.id_student;
      this.statusFrm.id_cs = item.id_course_student;
      if (item.status === "Retirado") {
        this.removeStudentMotive = item?.reason_for_unsubscribing ? item.reason_for_unsubscribing : "No se ha agregado motivo";
      }
    },

    closeReubicar() {
      this.dialogReubicar = false;
    },

    modalReubicar() {
      this.getCourseReubicar();
      this.dialogReubicar = true;
    },

    cleanReubicar() {
      this.reubicarFrm = {};
      this.getStudents();
    },

    reubicar: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "course_student/change_course",
          this.toFormData(this.reubicarFrm)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeReubicar();
            this.cleanReubicar();
            this.getCourse();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    closeStatus() {
      this.removeStudentMotive = "";
      this.dialogStatus = false;
    },

    modalStatus() {
      this.dialogStatus = true;
    },

    cleanStatus() {
      this.statusFrm = {};
    },

    changeStatus: async function(status) {
      // id course student y el status
      this.statusLoad = true;
      const motive = status === 1 ? null : this.removeStudentMotive; 

      await this.$http
        .post(
          this.api + "course_student/status",
          this.toFormData({
            id_course_student: this.statusFrm.id_cs,
            status: status,
            reason_for_unsubscribing: motive
          })
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeStatus();
            this.cleanStatus();
            this.getCourse();
          }
          // Reload students data
          this.getStudents();
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.statusLoad = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      actions: "getCurrentActions",
    }),
    labelsData() {
      let headers = {};
      let localHeaders = this.data.headers;
      if (localHeaders.length > 0) {
        localHeaders.forEach((header) => {
          let excluir = ["Acciones"];
          if (!this.searchInArray(excluir, header.text)) {
            headers[header.value] = { title: header.text };
          }
        });
      }
      return headers;
    },
    csvData() {
      let data = [];
      //de esta manera le quito la reactividad a la variables
      let localDataJSON = JSON.stringify(this.data.data);
      let localdata = JSON.parse(localDataJSON);
      //transformando data
      if (localdata.length > 0) {
        localdata.forEach((item) => {
          //cambios por key
          for (var [key, value] of Object.entries(item)) {
            if (key == "final_score") {
              if (item.final_score == null) {
                item.final_score = "";
              }
            }
          }
          data.push(item);
        });
      }
      return data;
    },
    isStudentRetired() {
      return this.selectedStudent.status === "Retirado";
    },
    HasMissingPayments() {
      return this.selectedStudent.pending_payments.length > 0;
    }
  },
  watch: {
    actions(val) {
      if (this.searchInArray(this.actions, "Reubicación")) {
        this.data.headers.push({
          text: "Acciones",
          value: "reubicar",
          sortable: false,
        });
      }
    },
  },
};
</script>

<style></style>
